import React, { useState } from "react";
import Pricing from "../components/pricing/Pricing";
import TopBanner from "../components/banner/TopBanner";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

function RiskCalculator() {
  const [accountSize, setAccountSize] = useState("");
  const [riskPerTrade, setRiskPerTrade] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [numberTrades, setNumberTrades] = useState("");
  const [ammountAtRisk, setAmmountAtRisk] = useState("");
  const [positionSize, setPositionSize] = useState("");
  const [positionEachTrade, setPositionEachTrade] = useState("");

  const calculatePositionSize = () => {
    const accountSizeNum = Number(accountSize);
    const riskPerTradeNum = Number(riskPerTrade);
    const stopLossNum = Number(stopLoss);
    const numberTradesNum = Number(numberTrades);

    const accountAtRisk = (accountSizeNum * riskPerTradeNum) / 100;

    // Postion Size Calculation
    const positionSizeCal = accountAtRisk / stopLossNum / 10;

    // Position for each Trade Calculator
    const positionTradeCal = positionSizeCal / numberTradesNum;

    // Update the position size state variable with the calculated value
    setAmmountAtRisk(accountAtRisk);
    setPositionSize(positionSizeCal);
    setPositionEachTrade(positionTradeCal);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Eltaajir - Risk Calculator</title>
        <link rel="canonical" href="https://eltaajir.com/risk-calculator" />
        <meta property="og:title" content="Eltaajir - Risk Calculator" />        
      </Helmet>
      <TopBanner title="Risk Calculator" />

      <section className="risk-calculator-section py-5">
        <Container>
          <Row>
            <Col lg={6} className="px-xl-5 px-lg-3 px-md-3">
              <div className="position-calculator">
                <div className="calculator-heading">
                  <h2>Position Size Calculator</h2>
                </div>
                <div className="calculator-div">
                  <div className="mb-3">
                    <label for="accountCurrency" className="form-label">
                      Account Currency
                    </label>
                    <select
                      className="form-select form-control"
                      aria-label="Default select example"
                    >
                      <option selected>Select Account Currency</option>
                      <option value="1">USD</option>
                      <option value="2">EUR</option>
                      <option value="3">CAD</option>
                      <option value="4">AUD</option>
                      <option value="5">NZD</option>
                      <option value="6">GBP</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Account Balance</label>
                    <input
                      type="text"
                      placeholder="Enter Account Balance"
                      className="form-control"
                      value={accountSize}
                      onChange={(e) => setAccountSize(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Risk Percentage{" "}
                      <span style={{ fontSize: "12px" }}>
                        we recommend you to use 1%
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Risk Percentage"
                      className="form-control"
                      value={riskPerTrade}
                      onChange={(e) => setRiskPerTrade(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Stop Loss (pips)</label>
                    <input
                      type="text"
                      placeholder="Enter Stop Loss (pips)"
                      className="form-control"
                      value={stopLoss}
                      onChange={(e) => setStopLoss(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Number if Trades expected to enter
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Number of Trades"
                      className="form-control"
                      value={numberTrades}
                      onChange={(e) => setNumberTrades(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Currency Pair</label>
                    <select
                      className="form-select form-control"
                      aria-label="Default select example"
                    >
                      <option selected>Select Currency Pair</option>
                      <option value="usoil">USOIL</option>
                      <option value="gold">GOLD</option>
                      <option value="eurusd">EURUSD</option>
                      <option value="gbpusd">GBPUSD</option>
                      <option value="gbpjpy">GBPJPY</option>
                      <option value="euraud">EURAUD</option>
                      <option value="audusd">AUDUSD</option>
                      <option value="nzdusd">NZDUSD</option>
                      <option value="usdcad">USDCAD</option>
                    </select>
                  </div>
                </div>
                <Button
                  className="calculator-btn mt-3"
                  onClick={calculatePositionSize}
                >
                  Calculate
                </Button>
              </div>
            </Col>
            <Col lg={6} className="px-xl-5 px-lg-3 px-md-3 ad-pading-top">
              <div className="calculator-right">
                <div className="calculator-heading">
                  <h2>Results</h2>
                  <div className="calculator-div calculator-result">
                    <div className="mb-3">
                      <label className="form-label">Amount at Risk</label>
                      <p>{ammountAtRisk ? ammountAtRisk : 0} USD</p>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Position Size</label>
                      <p>{positionSize ? positionSize : 0}</p>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Position for Each Trade
                      </label>
                      <p>{positionEachTrade ? positionEachTrade : 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Pricing /> */}
    </React.Fragment>
  );
}

export default RiskCalculator;
