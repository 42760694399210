import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation } from "@tanstack/react-query";
import { createReviewData } from "../api/testimonialApi";
import { toast } from "react-toastify";
import TopBanner from "../components/banner/TopBanner";
import { countryList } from "../api/pricingApi";
import { Helmet } from "react-helmet";

const CreateReview = () => {
  const [countries, setCountries] = useState([]);
  const intialData = {
    title: "",
    client_name: "",
    review: "",
    country: "",
    title_ar: "",
    client_name_ar: "",
    review_ar: "",
    country_ar: "",
  };
  const [reviewData, setReview] = useState(intialData);
  const [validation, setValidation] = useState(intialData);
  const [countryVal, setCountryVal] = useState("");

  useEffect(() => {
    countryListAction.mutate();
  }, []);

  const createReviewMutation = useMutation(createReviewData, {
    onSuccess: (data) => {
      if (data?.data) {
        setReview(intialData);
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }

      return;
    },

    onError: (error) => {
      if (error?.response?.data?.errors) {
        toast.error("Some Field is Required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        return;
      }
    },
  });

  const countryListAction = useMutation(countryList, {
    onSuccess: (data) => {
      if (data?.data) {
        setCountries(data.data);
      }
      return;
    },
    onError: (error) => {
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const handleChangeInput = ({ target }) => {
    const name = target && target.name;
    const value = target && target.value;
    setReview({
      ...reviewData,
      [name]: value,
    });
    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const handleCountryChange = ({ target }) => {
    const value = target && target.value;
    setCountryVal(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createReviewMutation.mutate(reviewData);
    }
  };

  const onReviewHandler = () => {
    if (validateForm()) {
      let reviewDetail;

      reviewDetail = {
        title: reviewData.title,
        client_name: reviewData.client_name,
        review: reviewData.review,
        country: countryVal,
      };

      createReviewMutation.mutate(reviewDetail);
    }
  };

  const validateForm = () => {
    let reviewDetail;

    reviewDetail = {
      title: reviewData.title,
      client_name: reviewData.client_name,
      review: reviewData.review,
      country: countryVal,
    };

    const formKeys = Object.keys(reviewDetail);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], reviewData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };

  const validate = (name, value) => {
    if (name === "title" || name === "title_ar") {
      if (!value) {
        return "Title is require";
      }
    } else if (name === "client_name" || name === "client_name_ar") {
      if (!value) {
        return "Name is require";
      }
    } else if (name === "review" || name === "review_ar") {
      if (!value) {
        return "Review is require";
      }
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>Eltaajir Payment</title>
        <link rel="canonical" href="https://eltaajir.com/payment" />
        <meta
          property="og:title"
          content="Eltaajir - Give Review"
        />
      </Helmet>
      <TopBanner title={"Add Review"} />
      <section className="create-review-page">
        <Container>
          <div className="create-review-wrap">
            <Row className="justify-content-center">
              <Col xs={12}>
                <div className="create-review-form">
                  <div className="form-title">
                    <h3>Create Review</h3>
                  </div>
                  <Form className="form-wrap">
                    <Form.Group
                      className="mb-3 re-title"
                      controlId="formGroupEmail"
                    >
                      <Form.Label className="form-new-lable">Title</Form.Label>
                      <div className="custome-input">
                        <Form.Control
                          className={`input-box ${
                            validation.title ? "error-add" : ""
                          }`}
                          placeholder="Enter Title"
                          type="title"
                          name="title"
                          value={reviewData.title}
                          onChange={handleChangeInput}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <span className="error-new">{validation.title}</span>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 re-client"
                      controlId="formGroupPassword"
                    >
                      <Form.Label className="form-new-lable">
                        Full Name
                      </Form.Label>
                      <div className="custome-input">
                        <Form.Control
                          className={`input-box ${
                            validation.client_name ? "error-add" : ""
                          }`}
                          placeholder="Enter Your Name"
                          type="client_name"
                          name="client_name"
                          value={reviewData.client_name}
                          onChange={handleChangeInput}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <span className="error-new">
                        {validation.client_name}
                      </span>
                    </Form.Group>
                    <div className="mb-3 re-country">
                      <label>Select Country</label>
                      <div className="coutry_select">
                        <div className="custome-input">
                          <Form.Select
                            name="Country"
                            value={countryVal}
                            onChange={handleCountryChange}
                            className="form-control w-100"
                          >
                            <option value="">Select Country</option>
                            {countries.map((country, index) => (
                              <option
                                key={index}
                                id={country.code}
                                value={country.code}
                              >
                                {country.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    <Form.Group
                      className="mb-3 re-desc"
                      controlId="formGroupPassword"
                    >
                      <Form.Label className="form-new-lable">Review</Form.Label>
                      <div className="custome-input">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className={`input-box ${
                            validation.review ? "error-add" : ""
                          }`}
                          placeholder="Enter Your Review"
                          type="review"
                          name="review"
                          value={reviewData.review}
                          onChange={handleChangeInput}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <span className="error-new">{validation.review}</span>
                    </Form.Group>
                    <Button
                      className="creat-review-btn"
                      variant="primary"
                      onClick={() => onReviewHandler()}
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};
export default CreateReview;
