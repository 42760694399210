import QRImage from "../../assets/images/success-image.svg";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBanner from "../../components/banner/TopBanner";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

export default function SuccessPage() {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const user = useSelector((state) => state.authUser).user;
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const [packgeData, setPackageData] = useState([]);
  const [buyPrice, setBuyPrice] = useState();

  useEffect(() => {
    sessionStorage.removeItem("paymentFormData");
    const buyPackege = urlParam.get("package_id");
    setBuyPrice(urlParam.get("amount"));
    if (urlParam.get("status")) {
      if (user && planPricing.length > 0) {
        const packege = planPricing.filter(
          (plan) => plan.id === Number(buyPackege)
        );
        setPackageData(packege[0]);
      }
    } else {
      navigate("/");
    }
  }, [user, planPricing]);

  return (
    <>
      <Helmet>
        <title>Eltaajir - Payment Successful </title>
        <link rel="canonical" href="https://eltaajir.com/payment-successful" />
        <meta
          property="og:title"
          content="Eltaajir - Payment Successful "
        />
      </Helmet>
      <TopBanner title="Payment Successful" />
      <section className="telegram-section">
        <Container className="px-md-5">
          <div className="success-box-container">
            <Row className="telegram-row  align-items-center">
              <Col lg={6}>
                <div className="qr-content">
                  <h1 className="mb-5">Payment Successful</h1>
                  <div className="payment-table-box">
                    <div className="payment-wrap d-flex">
                      <p className="w-35">Name:</p>
                      <p>
                        <span>
                          {user?.first_name} {user?.last_name}
                        </span>
                      </p>
                    </div>
                    <div className="payment-wrap d-flex">
                      <p className="w-35">Package Name:</p>
                      <p>
                        <span>{packgeData?.package_name}</span>
                      </p>
                    </div>
                    <div className="payment-wrap d-flex mb-4">
                      <p className="w-35">Price:</p>
                      <p>
                        <span>&euro; {buyPrice}</span>
                      </p>
                    </div>
                  </div>
                  <div className="nav-signup-btn">
                    <Button onClick={() => navigate("/")}>
                      Back To Home Page
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="qr-img">
                  <img className="img-fluid" src={QRImage} alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}
