import React from "react";
import HeroSection from "../components/lead/Hero-section";
import AboutMerchant from "../components/lead/AboutMerchant";
import { Helmet } from "react-helmet";
import Pricing from "../components/pricing/Pricing";

export default function MerchantLead() {
  return (
    <div className="education-etijjar-page">
      <Helmet>
        <title>Eltaajir - Forex Trading Education</title>
        <link rel="canonical" href="https://eltaajir.com/lead" />
        <meta property="og:title" content="Eltaajir - Forex Trading Education" />        
      </Helmet>
      <HeroSection />
      {/* <Pricing /> */}
      <AboutMerchant />
    </div>
  );
}
