import React, { useEffect, useRef, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import heshamImg from "../../assets/images/hesham-about-left.png";
import { useSelector, useDispatch } from "react-redux";

export default function Banner() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const user = useSelector((state) => state.authUser).user;
  const [token, setToken] = useState("");

  useEffect(() => {
    const havetoken = sessionStorage.getItem("tm-token");
    if (havetoken) {
      setToken(havetoken);
    }
    if (videoRef.current) {
      videoRef.current.onloadeddata = () => {
        videoRef.current.play();
      };
    }
  }, []);

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
      {/* Hero banner start */}
      <section className="home-hero-banner-top">
        <Container>
          <div className="banner-top-wrapper">
            <Row className="align-items-center">
              <Col xs={12} md={8} xxl={8} className="banner-top-left-col">
                <div className="banner-top-ctn position-relative">
                  <div className="banner-ctn-inner">
                    {/* <h2>What does it mean <span>eltaajir</span>?</h2> */}
                    <h1>
                      Master <span>Forex & Crypto Trading</span> with Confidence
                    </h1>
                    <p>
                      Turn uncertainty into opportunities. With Eltaajir, gain
                      access to expert-designed courses, practical strategies,
                      and the tools you need to <span>trade smarter</span> and{" "}
                      <span>achieve financial freedom</span>.
                    </p>
                    <p className="banner-second-paragraph">
                      Start your journey today!
                    </p>
                    <div className="banner-btn-grp">
                      <a href={user ? "/myplan" : "/login"} className="btn">
                        Start Your Journey For Free
                      </a>
                      {/* <a
                        href={user ? "/product-service" : "/login"}
                        className="btn"
                      >
                        Join the Journey
                      </a> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4} xxl={4} className="banner-top-right-col">
                <div className="banner-top-img">
                  <img src={heshamImg} alt="Hesham" className="w-100" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* Hero banner end */}
    </>
  );
}
