import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import telegramImg from "../assets/images/qr-image.svg";
import { Helmet } from "react-helmet";

export default function Telegram() {
  return (
    <div>
      <Helmet>
        <title>Eltaajir - Telegram</title>
        <link rel="canonical" href="https://eltaajir.com/telegram-qr" />
        <meta property="og:title" content="Eltaajir - Telegram" />  
      </Helmet>
      <section className="telegram-section position-relative">
        <Container>
          <div className="telegram-wrapper">
            <h1 className="mb-5 text-center">QR CODE SCAN</h1>
            <div className="telegram-img">
              <Image src={telegramImg} fluid />
            </div>
            <ul className="listDisc">
              <li>Scan the QR code to join the free Telegram channel.</li>
            </ul>
            <p>
              Declaration before joining the private Telegram channel (Signals
              group)
            </p>
            <p>
              By ticking the box below, you declare that you will follow and
              accept the following points:
            </p>
            <ul>
              <li>
                Forex Market is high volatile market, and most traders lose
                their money due to lack of strategy, risk management,
                discipline, consistency, and ability to understand the nature of
                the market,
              </li>
              <li>
                Every single trade I post in the channel is the trade which I
                personally take with my personal forex account,
              </li>
              <li>
                Forex market results need to be measured in the long term not by
                day, week, or month. Therefore, I will be focusing mainly on
                results per quarter and that’s why minimum commitment for
                signals group which is part of package 2 is 3-month,
              </li>
              <li>
                I will be sharing results from my side on the Telegram group or
                social media per day or week (whenever I can) but results of the
                services for our community will be measured per quarter,
              </li>
              <li>
                Never ever risk more than 1% per trade from your capital. In
                case we enter multiple trades at the same time you will need to
                divide the 1% across the trades. You can use this tool to help
                you in calculating this Link to the tool
              </li>
              <li>
                As you going to be part of the private Telegram channel then you
                going to do the following:
                <ul>
                  <li>
                    strictly following my strategy and my recommendation for
                    every single trade I post or enter, all stop losses (SL) and
                    take profits (SL)
                  </li>
                  <li>
                    you cannot be selective by choosing which trades you enter,
                    and which don’t. if you do, so any results will be your own
                    responsibility
                  </li>
                  <li>
                    for the swing trader you will have 1-2 hours to set up the
                    trade inside your platform
                  </li>
                  <li>
                    you will not be sharing or selling anything from the group
                    or courses with anyone else. In case I will find out that or
                    our bot will notice that you did that we reserve the right
                    to restrict you from the services
                  </li>
                </ul>
              </li>
              <li>
                you will not be sharing or selling anything from the group or
                courses with anyone else. In case I will find out that or our
                bot will notice that you did that we reserve the right to
                restrict you from the services
              </li>
              <li>
                Forex market need to be treated as business and results need to
                be treated in the long term. The most important thing which you
                need to know and learn in this group is discipline, risk
                management, consistency and the right mindset of a trader, money
                will come as results.
              </li>
              <li>
                The account balance is requested so I will be able to calculate
                results across the community.
              </li>
            </ul>
          </div>
        </Container>
      </section>
    </div>
  );
}
