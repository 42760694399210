import React, { useEffect, useState } from "react";
import { CloseButton, Col, Modal, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Logo from "../assets/images/eltaajir-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Google from "../assets/images/icons8-google.svg";
import FaceBook from "../assets/images/icons8-facebook.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { register, socialSignUp } from "../api/authApi";
import Loader from "../components/loader/loader";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import { countryList } from "../api/pricingApi";
import thankYou from "../assets/images/thank-you.png";
import BackBtn from "../assets/svg/BackBtn";
import Fetcher from "../library/Fetcher";

export default function SignUp() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParam = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [openThankReg, setOpenThankReg] = useState(false);
  const [countries, setCountries] = useState([]);
  const [signData, setSignData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    contact: "",
    password: "",
    confirm_password: "",
    country: "",
  });
  const [validation, setValidation] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    contact: "",
    password: "",
    confirm_password: "",
    country: "",
  });

  useEffect(() => {
    countryListAction.mutate();
    localStorage.setItem(
      "pay_ex_intel",
      JSON.stringify(
        urlParam.entries().reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {})
      )
    );
  }, []);

  const countryListAction = useMutation(countryList, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setCountries(data.data);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setSignData({
      ...signData,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const handlePhoneChange = (event, country_code, name) => {
    setSignData({
      ...signData,
      [name]: "+" + event,
    });
    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const registerUserMutation = useMutation(register, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        toast.success(data.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        if (urlParam.get("plan_id") && urlParam.get("mode")) {
          sessionStorage.setItem("tm-token", data?.data?.token);
          Fetcher.axiosSetup.defaults.headers.common.Authorization = `Bearer ${data?.data?.token}`;
          navigate("/");
          return;
        }else{
          sessionStorage.setItem("tm-token", data?.data?.token);
          Fetcher.axiosSetup.defaults.headers.common.Authorization = `Bearer ${data?.data?.token}`;
          navigate('/')
        }
      }
      
    },
    onError: (error) => {
      setLoading(false);
      const formKeys = error.response.data.errors;

      let grt_validation = {};
      for (let er in formKeys) {
        grt_validation[er] = formKeys[er];
      }

      setValidation(grt_validation);
    },
  });

  const onSignup = async () => {
    if (validateForm()) {
      setLoading(true);
      registerUserMutation.mutate(signData);
    }
  };

  const socialMutation = useMutation(socialSignUp, {
    onSuccess: (data) => {
      setLoading(false);
      if (data) {
        window.location.replace(data?.data);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const handleSocialLogin = (name) => {
    setLoading(true);
    socialMutation.mutate(name);
  };

  const validateForm = () => {
    const formKeys = Object.keys(signData);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], signData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };

  const validate = (name, value) => {
    if (name === "first_name") {
      if (!value) {
        return "Please enter first name";
      }
    } else if (name === "last_name") {
      if (!value) {
        return "Please enter last name";
      }
    } else if (name === "email") {
      var pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value === "exist") {
        return "The email has already been taken";
      }
      if (value) {
        if (!pattern.test(value)) {
          return "Please enter valid email address";
        }
      } else {
        return "Please enter your email address";
      }
    } else if (name === "confirm_email") {
      if (!value) {
        return "Please confirm your email";
      } else if (value !== signData.email) {
        return "Confirm email does not matched";
      }
    } else if (name === "password") {
      if (!value) {
        return "Please enter password";
      }
    } else if (name === "confirm_password") {
      if (!value) {
        return "Please confirm your password";
      } else if (value !== signData.password) {
        return "Confirm password does not matched";
      }
    } else if (name === "country") {
      if (!value) {
        return "Please select your country";
      }
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>Eltaajir - Sign up</title>
        <link rel="canonical" href="https://eltaajir.com/signup" />
        <meta property="og:title" content="Eltaajir - Sign up" />
      </Helmet>
      {loading && <Loader />}
      <section className="login-page signup-page">
        <div className="logoin-box-shadow">
          <Row className="p-0 m-0">
            <Col md={6} lg={6} className="p-0 m-0">
              <div className="btn-wrapper">
                <button className="back-tab" onClick={() => navigate("/")}>
                  <BackBtn /> Back to main page
                </button>
              </div>
              <div className="login-img">
                <Image src={require("../assets/images/signup-new-img.png")} className="w-100" />
              </div>
            </Col>
            <Col md={6} lg={6} className="p-0 m-0">
              <div className="login-form-one">
                <div className="logo-login">
                  <Image src={Logo} onClick={() => navigate("/")} />
                </div>
                <p className="signup-msg">Sign up into your account</p>
                <Form className="log-in-input">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupFirstName">
                        <Form.Label className="">First name</Form.Label>
                        <Form.Control
                          className={`input-box ${validation.first_name ? "error-add" : ""}`}
                          type="text"
                          placeholder="Enter Your First Name"
                          value={signData.first_name}
                          name="first_name"
                          onChange={handleChangeInput}
                        />
                        <span className="error-new">{validation.first_name}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupLastName">
                        <Form.Label className="form-new-lable">Last name</Form.Label>
                        <Form.Control
                          className={`input-box ${validation.last_name ? "error-add" : ""}`}
                          type="text"
                          placeholder="Enter Your Last name"
                          name="last_name"
                          value={signData.last_name}
                          onChange={(e) => handleChangeInput(e)}
                        />
                        <span className="error-new">{validation.last_name}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label className="form-new-lable">Email</Form.Label>
                        <Form.Control
                          className={`input-box ${validation.email ? "error-add" : ""}`}
                          type="email"
                          placeholder="info@gmail.com"
                          name="email"
                          value={signData.email}
                          onChange={(e) => handleChangeInput(e)}
                        />
                        <span className="error-new">{validation.email}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupConfEmail">
                        <Form.Label className="form-new-lable">Confirm Email </Form.Label>
                        <Form.Control
                          className={`input-box ${validation.confirm_email ? "error-add" : ""}`}
                          type="email"
                          placeholder="info@gmail.com"
                          name="confirm_email"
                          value={signData.confirm_email}
                          onChange={(e) => handleChangeInput(e)}
                        />
                        <span className="error-new">{validation.confirm_email}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupMobile">
                        <PhoneInput
                          specialLabel="Mobile number"
                          country={"pl"}
                          inputProps={{
                            name: "contact",
                          }}
                          placeholder="+91 1234567890"
                          value={signData.contact}
                          onChange={(e, country_code) => handlePhoneChange(e, country_code, "contact")}
                          inputClass="input-box"
                        />
                        <span className="error-new">{validation.contact}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3 re-country">
                        <label className="form-new-lable">Country</label>
                        <div className="coutry_select">
                          <div className="custome-input">
                            <Form.Select
                              name={"country"}
                              value={signData.country}
                              onChange={(e) => handleChangeInput(e)}
                              className={`form-control w-100 input-box${validation.country ? " error-add" : ""}`}
                            >
                              <option value="--" selected>
                                Select Country
                              </option>
                              {countries.map((country, index) => (
                                <option key={index} id={country.code} value={country.code}>
                                  {country.name}
                                </option>
                              ))}
                            </Form.Select>
                            <span className="error-new">{validation.country}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label className="form-new-lable">Password </Form.Label>
                        <Form.Control
                          className={`input-box ${validation.password ? "error-add" : ""}`}
                          type="password"
                          placeholder="xxxxxxxxx"
                          name="password"
                          value={signData.password}
                          onChange={(e) => handleChangeInput(e)}
                        />
                        <span className="error-new">{validation.password}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupConfPassword">
                        <Form.Label className="form-new-lable">Confirm password </Form.Label>
                        <Form.Control
                          className={`input-box ${validation.confirm_password ? "error-add" : ""}`}
                          type="password"
                          placeholder="xxxxxxxxx"
                          name="confirm_password"
                          value={signData.confirm_password}
                          onChange={(e) => handleChangeInput(e)}
                        />
                        <span className="error-new">{validation.confirm_password}</span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="btm-msg-link">
                    <div className="tnc">
                      <p>
                        *By clicking 'Sign Up' you automatically agree to our{" "}
                        <Link to={"/terms-and-conditions"} target="_blank">
                          Term and Conditions
                        </Link>
                        ,{" "}
                        <Link to={"/privacy-policy"} target="_blank">
                          Privacy Policy
                        </Link>{" "}
                        and{" "}
                        <Link to={"/fca-disclaimer"} target="_blank">
                          FCA Disclaimer
                        </Link>
                        .
                      </p>
                    </div>
                    <div className="forget-password">
                      Already have an account?
                      <Link to="/login">Login</Link>
                    </div>
                  </div>
                  <Button className="login-btn" variant="primary" onClick={() => onSignup()}>
                    Sign up
                  </Button>
                </Form>

                <div className="separator"></div>
                <Button className="facebook-btn" variant="primary" onClick={() => handleSocialLogin("facebook")}>
                  <Image src={FaceBook} /> Log in from Facebook
                </Button>
                <Button className="google-btn" variant="primary" onClick={() => handleSocialLogin("google")}>
                  <Image src={Google} /> Log in from Google
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Modal show={openThankReg} centered className="thank-you-modal">
          <ModalHeader>
            <div className="ty-logo">
              <Image src={Logo} onClick={() => navigate("/")} />
            </div>
          </ModalHeader>
          <Modal.Body>
            <div className="ty-box-inner">
              <div className="ty-body">
                <Image src={thankYou} />
              </div>
            </div>
          </Modal.Body>
          <ModalFooter>
            <p>
              Click here to continue <Link to={"/login"}>Login</Link>
            </p>
            <CloseButton className="close-btn" onClick={() => setOpenThankReg(false)}></CloseButton>
          </ModalFooter>
        </Modal>
      </section>
    </>
  );
}
