import React from "react";

import { Helmet } from "react-helmet";
import Banner from "./Banner";
import Courses from "./Courses";
import Benifit from "./Benifit";
import Testimonials from "../../components/testimonials/Testimonials";


export default function HomePage() {
  
  return (
    <>
      <Helmet>
        <title>Eltaajir- Forex & Crypto Trading</title>
        <meta property="og:title" content="Eltaajir- Forex & Crypto Trading" />        
        <link rel="canonical" href="https://eltaajir.com/" />
      </Helmet>
      <Banner />
      <Testimonials/>
      <Benifit/>
      <Courses/>
    </>
  );
}
