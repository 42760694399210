import React from "react";
import { Helmet } from "react-helmet";
import TopBanner from "../components/banner/TopBanner";
import { Col, Container, Row } from "react-bootstrap";

export default function FcaRegulator() {
  return (
    <>
      <Helmet>
        <title>
          Eltaajir - Terms and Conditions
        </title>
        <link
          rel="canonical"
          href="https://eltaajir.com/terms-and-conditions"
        />
        <meta property="og:title" content="Eltaajir - Terms and Conditions" />
      </Helmet>
      <TopBanner title="FCA DISCLAIMER" />
      <section className="fac-disc-sec mt-tnc-sec">
        <Container>
          <div className="main-policy">
            <Row>
              <Col
                lg={12}
                className="mt-tnc-ctn fca-disc-ctn  my-xl-5 my-lg-5 my-4"
              >
                <h4 className="update">Last updated:  January 01, 2024</h4>
                <h4 className="update">
                  The Merchant Hesham Ahmed Elsaid Ahmed Abdelfattah called here
                  as “The Merchant and/or Eltaajir”
                </h4>

                <h3 className="fca-title">
                  The Merchant – Eltaajir, a company registered in Poland, would
                  like to make it explicitly clear that it is not regulated by
                  the Financial Conduct Authority (FCA).
                </h3>
                <p className="imp-point">Important Points to Note:</p>
                <ul>
                  <li>
                    <p>
                      <strong>Non-Regulated Status:</strong> The Merchant is not
                      authorized or regulated by the FCA to provide financial
                      services or products.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Informational Purposes:</strong> Any information
                      provided on our website, in our communications, or through
                      the services we provide is for informational purposes only
                      and should not be considered as financial advice or a
                      recommendation to engage in financial transactions.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>No Client Relationships:</strong> We do not offer
                      or establish client relationships based on financial
                      advisory or investment services. Any engagement with our
                      company should not be misconstrued as a financial advisory
                      relationship.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Independent Decision-Making:</strong>  Individuals
                      should exercise their own judgment and seek professional
                      advice from authorized financial institutions or advisors
                      when making financial decisions. Reliance on information
                      provided by Eltaajir is done at the individual's own risk.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>No FCA Protections:</strong> Customers and clients
                      of The Merchant should be aware that any financial
                      products or services we offer do not benefit from the
                      protections provided by the FCA's regulatory framework,
                      including the Financial Services Compensation Scheme
                      (FSCS).
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Complaints and Grievances:</strong> While The
                      Merchant is committed to providing excellent customer
                      service, individuals should be aware that they do not have
                      recourse to the Financial Ombudsman Service (FOS) or FSCS
                      in case of disputes or grievances.
                    </p>
                  </li>
                </ul>
                <h4>
                  By accessing our services and information, you acknowledge and
                  understand the above-stated points regarding The Merchant's
                  regulatory status. We strongly advise consulting with
                  authorized financial professionals or institutions for any
                  financial advice or services.
                </h4>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}
