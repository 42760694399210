import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackBtn from "../assets/svg/BackBtn";
import { getEbookById } from "../api/eBookApi";
import { Row } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import PrevVidIcon from "../assets/svg/PrevVidIcon";
import NextVidIco from "../assets/svg/NextVidIco";
import { useMutation } from "@tanstack/react-query";
import { setUserTrackApi } from "../api/videoDetailsApi";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFRead() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [currentEbook, setCurrentEbook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const redirect = searchParams.get("tp");

  useEffect(() => {
    const direct = searchParams.get("d");
    if (direct) {
      getCurrentEbookById(direct);
    }
  }, []);

  const getCurrentEbookById = async (id) => {
    const ncurrentEbook = await getEbookById(id).then(
      (res) => res.data.data[0]
    );
    setCurrentEbook(ncurrentEbook);
  };

  function onDocumentLoadSuccess({ numPages }) {
    const direct = searchParams.get("d");
    let pdfDetails = {
      ebook_id: direct,
    };

    setNumPages(numPages);
    setLoading(false);
    setUserTrackApiMutation.mutate(pdfDetails);
  }

  const setUserTrackApiMutation = useMutation(setUserTrackApi, {
    onSuccess: async (data) => {
      return;
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      let myValue = Number(e.target.value);
      if (myValue > numPages) {
        myValue = numPages;
      } else if (e.target.value <= 0) {
        myValue = 1;
      }
      inputRef.current.value = myValue;
      setPageNumber(myValue);
    }
  };

  const pagehandle = (num) => {
    setPageNumber(pageNumber + num);
    inputRef.current.value = pageNumber + num;
  };

  return (
    <>
      <Helmet>
        <title>Eltaajir</title>
        <link rel="canonical" href="https://eltaajir.com/read" />
        <meta property="og:title" content="Eltaajir" />
      </Helmet>
      <section className="vid-list-sec pdf-view-sec">
        <div className="container-fluid">
          <div className="btn-wrapper">
            <button
              className="back-tab"
              onClick={() =>
                navigate("/myplan", { state: { active: redirect } })
              }
            >
              <BackBtn /> Back
            </button>
          </div>
          <Row>
            <div className="pdf-sec-wrapper">
              {currentEbook && (
                <Document
                  file={currentEbook?.e_aws_url}
                  renderMode="svg"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    scale={1.2}
                  />
                </Document>
              )}
              {!loading && (
                <div className="pdf-footer-nav">
                  <button
                    disabled={pageNumber === 1}
                    onClick={() => pagehandle(-1)}
                  >
                    <PrevVidIcon />
                  </button>{" "}
                  <span>
                    {" "}
                    <input
                      defaultValue={1}
                      ref={inputRef}
                      className="input-page"
                      type="number"
                      onKeyUp={handleKeyUp}
                    />{" "}
                    of {numPages}
                  </span>{" "}
                  <button
                    onClick={() => pagehandle(1)}
                    disabled={pageNumber === numPages}
                  >
                    <NextVidIco />
                  </button>
                </div>
              )}
            </div>
            <div></div>
          </Row>
        </div>
      </section>
    </>
  );
}

export default PDFRead;
