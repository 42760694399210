import React, { useState, useEffect } from "react";
import { Button,  Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import TopBanner from "../components/banner/TopBanner";
import { Helmet } from "react-helmet";
import userIcon from "../assets/images/defaultUser.png";
import { useMutation } from "@tanstack/react-query";
import Fetcher from "../library/Fetcher";
import { toast } from "react-toastify";
import { backend_url } from "../../src/config/constants";
import { countryList } from "../api/pricingApi";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { handleUser } from "../reducers/userSlice";
import { phones_validation } from "../config/constants";

function ProfilePage() {
  const user = useSelector((state) => state.authUser).user;
  const dispatch = useDispatch();
  const [imageErr, setImageErr] = useState("");
  const [countrycode, setCountryCode] = useState("");
  const [editEmail, setEditEmail] = useState(false);
  const [countries, setCountries] = useState([]);
  const [imageSrc, setImageSrc] = useState("");
  const [profile, setProfile] = useState({});
  const [validation, setValidation] = useState({
    first_name: "",
    last_name: "",
    contact: "",
    country: "",
    forex_rating: "",
    crypto_rating: "",
    email: "",
    image: "",
  });

  useEffect(() => {
    countryListAction.mutate();
  }, []);

  useEffect(() => {
    if (user) {
      const details = {
        first_name: user.first_name,
        last_name: user.last_name,
        contact: user.contact,
        email: user.email,
        country: user.country,
        forex_rating: user.forex_rating,
        crypto_rating: user.crypto_rating,
        image: user.image,
      };

    
      setEditEmail(user.email !== null ? true : false);
      setProfile(details);
    }
  }, [user]);


  const handleChange = ({ target }) => {
    const { name, value } = target;
    setProfile({ ...profile, [name]: value });
  
    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const handleRate = (value, type) => {
    setProfile({ ...profile, [type]: value });

    setValidation({
      ...validation,
      [type]: "",
    });
  };

  const handlePhoneChange = (event,country_code, name) => {
    setProfile({
      ...profile,
      [name]: "+" + event,
    });

    setCountryCode(country_code)

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const updateProfile = useMutation(
    (formData) => Fetcher.post(`/updateProfile`, formData),
    {
      onSuccess: (data) => {
        
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(handleUser(data?.data.user));
      },
      onError: (error) => {
        console.log("error");
      },
    }
  );

  const updateProfileHandler = async (e) => {
    e.preventDefault();
    setImageErr("");
    if (validateForm()) {
      let formData = new FormData();
      for (let pk in profile) {
        formData.append(pk, profile[pk] ? profile[pk] : "");
      }      
      updateProfile.mutate(formData);
    }
  };

  const validate = (name, value, updateValue) => {
    if (name === "first_name") {
      if (!value && !updateValue) {
        return "Please enter first name";
      }
    } else if (name === "last_name") {
      if (!value) {
        return "Please enter last name";
      }
    } else if (name === "contact") {
      if (!value) {
        return "Please enter  mobile number";
      } else {
        if(countrycode){
          var phoneregax =
            phones_validation[countrycode?.countryCode.toUpperCase()];
          if (!phoneregax.test(value)) {
            return "Invalid mobile number";
          }
        }
      }
    } else if (name === "email") {
      var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value) {
        if (!pattern.test(value)) {
          return "Please enter valid email address";
        }
      } else {
        return "Please enter your email address";
      }
  
    } else if (name === "country") {
      if (!value) {
        return "Please select country";
      }
    } else if (name === "forex_rating") {
      if (!value) {
        return "Please rate your experience";
      }
    } else if (name === "crypto_rating") {
      if (!value) {
        return "Please rate your experience";
      }
    }
    return true;
  };

  const validateForm = () => {
    const formKeys = Object.keys(profile);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const formDataVal = profile[formKeys[i]];
      const c_validate = validate(formKeys[i], formDataVal);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };

  const handleProfileChange = ({ target }) => {
    setImageErr("");
    const value = target && target.files[0];
    validate("image", value);
    if (!value?.type.match(/^image\/(jpeg|png|gif)$/)) {
      setImageErr("Please select an image file");
      return;
    }

    setImageSrc(URL.createObjectURL(value));
    setProfile({ ...profile, image: value });
  };

  const countryListAction = useMutation(countryList, {
    onSuccess: (data) => {
      
      if (data?.data) {
        setCountries(data.data);
      }
      return;
    },
    onError: (error) => {
      
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>Eltaajir - Profile </title>
        <link rel="canonical" href="https://eltaajir.com/profile" />
        <meta property="og:title" content="Eltaajir - Profile" />        
      </Helmet>
      <TopBanner title="Your Profile" />
      <section className="white-section profile-page">
        <Container className="px-6">
          <div className="profile-page-inner row row-cols-2 g-4">
            <div className="col-12 col-xxl-3 col-xl-4 col-md-12">
              <div className="profile-img-sec">
                <div className="profile-img-wrap">
                  <img
                    src={
                      imageSrc
                        ? imageSrc
                        : profile?.image
                        ? `${profile.image}`
                        : userIcon
                    }
                    alt="profile"
                    className={`mb-2 ${profile.image ?'active' : ""}`}
                  />
                </div>
                <h4>
                  {profile.first_name} {profile.last_name}
                </h4>
                <div className="edit-pic-btn-sec">
                  <Button>Upload New Photo</Button>
                  <input
                    className="input-img w-100"
                    type="file"
                    name="image"
                    onChange={handleProfileChange}
                  />
                  <span className="error-new d-block">{imageErr}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-xxl-9 col-xl-8 col-md-12">
              <div className="profile-box">
                <h3>Profile Setting</h3>
                <Form
                  className="profile-box-form row row-cols-1 row-cols-sm-2"
                  onSubmit={updateProfileHandler}
                >
                  <Form.Group className="mb-3">
                    <Form.Label className="form-new-lable">
                      First name
                    </Form.Label>
                    <Form.Control
                      className={`input-box ${
                        validation.first_name ? "error-add" : ""
                      }`}
                      placeholder="Enter First name"
                      type="text"
                      name="first_name"
                      value={profile?.first_name && profile?.first_name}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <span className="error-new">{validation.first_name}</span>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="form-new-lable">
                      Last name
                    </Form.Label>
                    <Form.Control
                      className={`input-box ${
                        validation.last_name ? "error-add" : ""
                      }`}
                      placeholder="Enter Last name"
                      type="last_name"
                      name="last_name"
                      value={profile?.last_name && profile?.last_name}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <span className="error-new">{validation.last_name}</span>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <PhoneInput
                      specialLabel="Mobile number"
                      inputClass="input-box"                      
                      placeholder="Enter Contact"
                      value={profile?.contact && profile?.contact}
                      inputProps={{
                        name: "contact",
                      }}
                      onChange={(e, country_code) =>
                        handlePhoneChange(e, country_code, "contact")
                      }
                    />
                    <span className="error-new">{validation.contact}</span>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="form-new-lable">Email</Form.Label>
                    <Form.Control
                      className={`input-box ${
                        validation.email ? "error-add" : ""
                      }`}
                      placeholder="Enter Email Id"
                      type="text"
                      name="email"
                      value={profile?.email && profile?.email}
                      autoComplete="off"
                      onChange={handleChange}
                      disabled={editEmail}
                    />
                    <span className="error-new">{validation.email}</span>
                  </Form.Group>

                  <Form.Group className="mb-3 re-country">
                    <Form.Label className="form-new-lable">Country</Form.Label>
                    <Form.Select
                      name="country"
                      value={profile?.country}
                      onChange={handleChange}
                      className="form-control w-100"
                      size="lg"
                    >
                      <option value="">Select Country</option>
                      {countries.map((country, index) => (
                        <option
                          key={index}
                          id={country.code}
                          value={country.code}
                        >
                          {country.name}
                        </option>
                      ))}
                    </Form.Select>
                    <span className="error-new">{validation.country}</span>
                  </Form.Group>

                  <Form.Group className="mb-3 rating col-sm-12">
                    <Form.Label className="form-new-lable">
                      How would you rate your experience / Knowledge in Forex?
                    </Form.Label>
                    <ul className="rating-number">
                      {Array.from({ length: 10 }, (_, i) => i + 1).map(
                        (element, idx) => {
                          return (
                            <li
                              key={idx}
                              className={`${
                                profile.forex_rating == element ? "active" : ""
                              }`}
                              onClick={() =>
                                handleRate(element, "forex_rating")
                              }
                            >
                              {element}
                            </li>
                          );
                        }
                      )}
                    </ul>
                    <span className="error-new">{validation.forex_rating}</span>
                  </Form.Group>

                  <Form.Group className="mb-3 rating col-sm-12">
                    <Form.Label className="form-new-lable">
                      How would you rate your experience / Knowledge in Crypto?
                    </Form.Label>
                    <ul className="rating-number">
                      {Array.from({ length: 10 }, (_, i) => i + 1).map(
                        (element, idx) => {
                          return (
                            <li
                              key={idx}
                              className={`${
                                profile.crypto_rating == element ? "active" : ""
                              }`}
                              onClick={() =>
                                handleRate(element, "crypto_rating")
                              }
                            >
                              {element}
                            </li>
                          );
                        }
                      )}
                    </ul>
                    <span className="error-new">
                      {validation.crypto_rating}
                    </span>
                  </Form.Group>

                  <div className="col-lg-12">
                    <Button className="lms-btn" variant="primary" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default ProfilePage;
