import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import aboutImg from "../../assets/images/hesham-about.png";
import { Helmet } from "react-helmet";
import TopBanner from "../../components/banner/TopBanner";

export default function Aboutme() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onloadeddata = () => {
        videoRef.current.play();
      };
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Hesham Elsaid - Forex & Crypto trader</title>
        <meta property="og:title" content="Hesham Elsaid - Forex & Crypto trader" />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      <TopBanner title="About Me" />
      <section className="aboutMe-section">
        <Container>
          <Row className="align-items-center">
            <Col>
              <div className="aboutMe-ctn-block">
                <Image src={aboutImg} fluid />
                <h2>About Hesham Elsaid</h2>
                <p>Welcome to the world of forex trading—where expertise meets real-world experience.</p>
                <p>
                  I’m Hesham Elsaid, a passionate trader, mentor, and entrepreneur, dedicated to helping aspiring traders master the forex market with
                  confidence and clarity.
                </p>
                <p>
                  My journey began in Alexandria, Egypt, where I developed a strong foundation in Computer & Electronics before expanding my education in Italy
                  and ultimately settling in Europe.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="aboutMe-section ctn-section">
        <Container>
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="aboutMe-ctn-block">
                <h2>From Corporate Success to Trading Mastery</h2>
                <p>
                  My forex trading adventure started in 2012, alongside a thriving corporate career. I spent 8 years at Intel in Poland as a Business
                  Development Manager, where I managed international accounts exceeding $1 billion. My experience in the corporate world sharpened my ability to
                  analyze markets, manage risks, and make strategic decisions—skills that later became invaluable in my trading career.
                </p>
                <p>
                  But my heart was always in the forex market. My early years were filled with challenges, financial losses, and emotional struggles, but I
                  refused to give up. I obsessively studied, refined my strategies, and learned from every setback. By 2019, I turned my results around,
                  becoming consistently profitable.
                </p>
                <p>
                  This breakthrough led me to mentoring traders in Poland and Italy, offering personalized 1:1 coaching to help others avoid the costly mistakes
                  I once made. In 2023, I took a bold step forward—establishing my own trading education company in Poland, empowering English and
                  Arabic-speaking traders worldwide.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="aboutMe-section ctn-section">
        <Container>
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="aboutMe-ctn-block">
                <h2>Beyond Trading</h2>
                <p>
                  Away from the charts, I’m deeply passionate about sports. I was a professional karate and soccer player and now enjoy soccer, squash, running,
                  and yoga. These activities keep me disciplined, focused, and balanced—qualities that directly impact my trading mindset.
                </p>
                <p>
                  But most importantly, trading gave me the financial freedom to escape the 9-to-5 grind and live life on my terms. Now, I’m on a mission to
                  help others achieve the same.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="aboutMe-section ctn-section">
        <Container>
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="aboutMe-ctn-block">
                <h2>My Mission: Helping You Master the Forex Market</h2>
                <p>
                  I don’t just teach forex strategies—I mentor traders to build a profitable, sustainable approach to trading. Here’s how I help my students
                  succeed:
                </p>
                <p>
                  <strong>1. Guiding Your Market Journey:</strong> With 10+ years of experience, I provide practical strategies, risk management techniques, and
                  market insights to help you trade with confidence.
                </p>
                <p>
                  <strong>2. Saving You Time and Money:</strong> Forex trading is full of pitfalls and expensive lessons. I help you avoid the costly mistakes
                  that most beginners make—so you can accelerate your success.
                </p>
                <p>
                  <strong>3. Building Passive Income:</strong> Trading is more than just charts—it’s a path to financial independence. I teach scalable,
                  long-term trading strategies that help you generate consistent income.
                </p>
                <p>
                  <strong>4. Achieving Financial Freedom:</strong> If you’re tired of the 9-5 cycle, I help traders create a roadmap toward true financial
                  independence, so you can take control of your future.
                </p>
                <p>
                  <strong>5. Protecting Your Wealth from Inflation:</strong> With the right trading skills, you can safeguard your savings, grow your wealth,
                  and stay ahead of inflation.
                </p>
                <p>
                  <strong>6. Developing Successful Forex Traders:</strong> Success in forex is a skill—not luck. I break down complex concepts into simple,
                  actionable steps to help you trade with precision and discipline.
                </p>
                <p>
                  <strong>7. Creating a Community of Conscious Traders:</strong> Join a global network of traders who share knowledge, support each other, and
                  grow together. You’ll never trade alone.
                </p>
                <p>
                  <strong>8. Keeping It Real—No Get-Rich-Quick Hype:</strong> Forget the fake promises of overnight riches. I teach real, sustainable trading
                  strategies so you can build long-term wealth with confidence.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="aboutMe-section ctn-section">
        <Container>
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="aboutMe-ctn-block">
                <h2>Join Me on This Journey</h2>
                <p>Mastering forex trading isn’t just about making money—it’s about building freedom, confidence, and a life on your terms.</p>
                <p>If you’re ready to take control of your financial future, let’s make it happen together.</p>
              </div>
            </Col>
          </Row>
          <div className="banner-btn-grp">
            <a href="/login" class="btn">Start Your Journey For Free</a>
          </div>
        </Container>
      </section>
    </div>
  );
}
