import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { promoApi, addPaymentDetails, cityApi } from "../../api/authApi";
import { countryList } from "../../api/pricingApi";
import Form from "react-bootstrap/Form";
import Loader from "../../components/loader/loader";
import TopBanner from "../../components/banner/TopBanner";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import rates from "../../assets/json/rates.json";
import { useSelector } from "react-redux";
import { disbledLanguage } from "../../config/constants";

function PaymentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const user = useSelector((state) => state.authUser);

  const [countries, setCountries] = useState([]);
  const [hasReturn, setHasReturn] = useState(null);
  const [cities, setCities] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [errorAggrement, setErrorAggrement] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    id: location?.state?.packageId,
    plan_id: "",
    language: "en",
    payment_type: "2",
    user_country: "",
    user_city: "",
    promocode: "",
    vat_amount: 0,
    vat_percent: 0,
    promo_amount: 0,
    total_price: 0,
    discount: 0,
    package_price: 0,
    is_term_accept: 0,
    is_confirm_payment_accept: 0,
  });

  useEffect(() => {
    countryListAction.mutate();
    const hasFormData = JSON.parse(localStorage.getItem("paymentFormData"));
    localStorage.removeItem("paymentFormData");
    if (!location.state && hasFormData == null) {
      navigate("/");
    }
    if (hasFormData) {
      navigate("/payment", { state: { packageId: hasFormData.id } });
      cityCodeMutation.mutate(hasFormData?.user_country);
      setFormData(hasFormData);
    } else {
      cityCodeMutation.mutate(hasFormData?.user_country);
    }
    setHasReturn(true);
  }, []);

  useEffect(() => {
    if (planPricing.length > 0 && hasReturn) {
      handlePlanData(formData.id);
    }
  }, [planPricing, hasReturn]);

  const handlePlanData = (package_id) => {
    const details = {};
    if (disbledLanguage.includes(Number(package_id))) {
      details["language"] = "en";
    }

    const planDataNew = planPricing.filter(
      (element) => element.id == package_id
    )[0];
    const package_price_new =
      ((planDataNew?.amount * 100) / (100 - planDataNew?.discount_amount)) *
      planDataNew?.duration_period;
    const discount_amount =
      package_price_new - planDataNew?.amount * planDataNew?.duration_period;

    let new_vat = { vat_amount: 0, vat_percent: 0 };
    if (formData.user_country) {
      new_vat = handleVat(formData.user_country, package_price_new);
    }

    const total_price =
      package_price_new +
        new_vat.vat_amount -
        formData.promo_amount -
        discount_amount >
      0
        ? package_price_new +
          new_vat.vat_amount -
          formData.promo_amount -
          discount_amount
        : 0;

    setPlanData(planDataNew);
    setFormData({
      ...formData,
      ...details,
      package_price: package_price_new,
      discount: discount_amount,
      vat_amount: new_vat.vat_amount,
      vat_percent: new_vat.vat_percent,
      id: package_id,
      plan_id: planDataNew?.type,
      total_price: total_price,
    });
  };

  const promoCodeMutation = useMutation(promoApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        let discount = data?.data?.data?.discount_amount;
        setFormData({
          ...formData,
          discount_apply_all_cycle: data?.data?.data?.discount_apply_all_cycle,
          promo_amount: discount,
          total_price:
            formData.total_price - discount > 0
              ? formData.total_price - discount
              : 0,
        });
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const cityCodeMutation = useMutation(cityApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setCities(data?.data);
      }
      if (location?.state?.packageId == null && location?.state?.id) {
        setFormData(location?.state);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const countryListAction = useMutation(countryList, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setCountries(data.data);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const paymentMutation = useMutation(addPaymentDetails, {
    onSuccess: (data) => {
      const pay_res_url = data.data.data.pay_response.url;
      if (pay_res_url) {
        window.location.replace(pay_res_url);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.errors, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const handleValidation = (data) => {
    let errors = {};
    let formIsValid = true;
    if (data.id) {
      if (data.id == user?.plans[0]?.package_id) {
        let currPckName;
        for (let i = 0; i < planPricing.length; i++) {
          if (planPricing[i].id == user?.plans[0]?.package_id) {
            currPckName = planPricing[i].package_name;
          }
        }
        errors["plan_id"] = `You already have "${currPckName}" plan`;
        formIsValid = false;
      }
      if (
        user?.plans[0]?.package_id <= 6 &&
        data.id < user?.plans[0]?.package_id
      ) {
        let currPckName;
        let newPckName;
        for (let i = 0; i < planPricing.length; i++) {
          if (planPricing[i].id == user?.plans[0]?.package_id) {
            currPckName = planPricing[i].package_name;
          }
          if (planPricing[i].id == data.id) {
            newPckName = planPricing[i].package_name;
          }
        }
        errors[
          "plan_id"
        ] = `You have "${currPckName}" plan which is include "${newPckName}" plan`;
        formIsValid = false;
      }
    }
    if (!data.user_country) {
      errors["user_country"] = "Country is Required";
      formIsValid = false;
    }
    if (!data.user_country) {
      errors["user_country"] = "Country is Required";
      formIsValid = false;
    }
    if (!data.user_city) {
      errors["user_city"] = "City is Required";
      formIsValid = false;
    }
    return { errors, formIsValid };
  };

  const handleToBuy = (e) => {
    localStorage.setItem("paymentFormData", JSON.stringify(formData));
    const validate = handleValidation(formData);
    if (!validate.formIsValid) {
      setErrors(validate.errors);
    } else {
      setErrors({});
      if (
        formData.is_term_accept === 1 &&
        formData.is_confirm_payment_accept === 1
      ) {
        setErrorAggrement("");
        const paymentData = {
          package_id: formData.id,
          plan_id: formData.plan_id,
          payment_method: formData.payment_type,
          plan_lang: formData.language,
          county: formData.user_country,
          vat_price: formData.vat_amount,
          city: formData.user_city,
          package_price: formData.package_price,
          total_price: formData.total_price,
          discount_code: formData.promocode,
          discount_price: formData.promo_amount + formData.discount,
          discount_apply_all_cycle: formData.discount_apply_all_cycle,
          is_term_accept: formData.is_term_accept,
          is_confirm_payment_accept: formData.is_confirm_payment_accept,
        };
        paymentMutation.mutate(paymentData);
      } else {
        setErrorAggrement("Please accept terms and condition");
      }
    }
  };

  const handlePromoClick = () => {
    const promo_data = {
      promocode: formData.promocode,
      user_id: user.user.id,
    };
    promoCodeMutation.mutate(promo_data);
  };

  const handleDurationChange = (e) => {
    errors["plan_id"] = "";
    const value = e.target ? e.target.value : "";
    handlePlanData(value);
  };

  const handleSelectChange = ({ target }) => {
    const { value, name } = target;
    const details = {
      [name]: value,
    };
    if (name == "user_country") {
      cityCodeMutation.mutate(value);

      const { vat_amount, vat_percent } = handleVat(
        value,
        formData.package_price
      );
      const total_price =
        formData.package_price +
        vat_amount -
        formData.promo_amount -
        formData.discount;
      details["vat_amount"] = vat_amount;
      details["vat_percent"] = vat_percent;
      details["total_price"] = total_price;
    }
    setErrors({ ...errors, [name]: "" });
    setFormData({ ...formData, ...details });
  };

  const handleVat = (country, totalPrice) => {
    const newrates = rates.rates[country];
    if (newrates) {
      const vat = (totalPrice * newrates.standard_rate) / 100;
      return { vat_amount: vat, vat_percent: newrates.standard_rate };
    }
    return { vat_amount: 0, vat_percent: 0 };
  };

  const checkTerm = ({ target }) => {
    if (target.checked) {
      setFormData({ ...formData, is_term_accept: 1 });
    } else {
      setFormData({ ...formData, is_term_accept: 0 });
    }
  };

  const checkConfirm = ({ target }) => {
    if (target.checked) {
      setFormData({ ...formData, is_confirm_payment_accept: 1 });
    } else {
      setFormData({ ...formData, is_confirm_payment_accept: 0 });
    }
  };

  const handleRemovePromoClick = () => {
    setFormData({
      ...formData,
      promo_amount: 0,
      promocode: "",
      discount_apply_all_cycle: 0,
      total_price: formData.total_price + formData.promo_amount,
    });
  };

  return (
    <>
      <Helmet>
        <title>Eltaajir Payment</title>
        <link rel="canonical" href="https://eltaajir.com/payment" />
        <meta
          property="og:title"
          content="Eltaajir- Forex & Crypto Trading "
        />
      </Helmet>
      {loading && <Loader />}
      <TopBanner title="Payment Information" />
      <section className="white-section payment-padding">
        <Container className="payment-main-box">
          <Row>
            <Col>
              <div className="course-details-wrapper">
                <h3 className="pkg-details mb-4">Package Details</h3>
                <div className="course-details">
                  <div className="pkg-title">
                    <div className="payment-feild mb-4">
                      <div className="coutry_select">
                        <Form.Select
                          onChange={handleDurationChange}
                          className="form-control w-100"
                          value={planData?.id}
                        >
                          {planPricing.length > 0 &&
                            planPricing.map((course, index) => {
                              return (
                                course.is_contact === 0 && (
                                  <option
                                    key={index}
                                    id={course.code}
                                    value={course.id}
                                  >
                                    {course.package_name}
                                  </option>
                                )
                              );
                            })}
                        </Form.Select>
                        {errors["plan_id"] && (
                          <p className="text-danger">{errors["plan_id"]}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pkg-info">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: planData?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="payment-details-wrapper">
                <h3 className="pkg-details mb-4">Checkout Details</h3>
                <div className="payment-details">
                  <form action="">
                    <div className="payment-feild mb-3">
                      <label className="lang">Select Payment Method</label>
                      <div className="payment-feild-btn radio-feild d-flex gx-2">
                        <div className="radio-wrapper position-relative d-flex align-items-center">
                          <input
                            type="radio"
                            id="credit"
                            name="payment_type"
                            value="2"
                            defaultChecked
                            onChange={handleSelectChange}
                          />
                          <label htmlFor="credit">Credit / Debit Card</label>
                        </div>
                        <div className="radio-wrapper position-relative d-flex align-items-center">
                          <input
                            type="radio"
                            id="paypal"
                            name="payment_type"
                            value="1"
                            onChange={handleSelectChange}
                          />
                          <label htmlFor="paypal">Paypal</label>
                        </div>
                      </div>
                    </div>
                    {!disbledLanguage.includes(Number(formData.id)) ? (
                      <div className="payment-feild mb-3">
                        <p className="lang">Select Course Language</p>
                        <div className="radio-feild d-flex gx-2">
                          <div className="radio-wrapper position-relative d-flex align-items-center">
                            <input
                              type="radio"
                              id="en"
                              name="language"
                              value="en"
                              checked={
                                formData.language === "en" ? true : false
                              }
                              onChange={handleSelectChange}
                            />
                            <label htmlFor="en">English</label>
                          </div>
                          <div className="radio-wrapper position-relative d-flex align-items-center">
                            <input
                              type="radio"
                              id="ar"
                              name="language"
                              value="ar"
                              checked={
                                formData.language === "ar" ? true : false
                              }
                              onChange={handleSelectChange}
                            />
                            <label htmlFor="ar">Arabic</label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="payment-feild mb-3">
                      <label>Select Country</label>
                      <div className="coutry_select">
                        <Form.Select
                          onChange={handleSelectChange}
                          name="user_country"
                          className="form-control w-100"
                          value={formData.user_country}
                        >
                          <option value="">Select Country</option>
                          {countries.map((country, index) => (
                            <option key={index} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors["user_country"] && (
                          <p className="text-danger">
                            {errors["user_country"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="payment-feild mb-3">
                      <label>Select City</label>
                      <div className="coutry_select">
                        <Form.Select
                          onChange={handleSelectChange}
                          name="user_city"
                          className="form-control w-100"
                          value={formData.user_city}
                        >
                          <option value="">Select City</option>
                          {cities.map((city, index) => (
                            <option key={index} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors["user_city"] && (
                          <p className="text-danger">{errors["user_city"]}</p>
                        )}
                      </div>
                    </div>
                    <div className="payment-feild mb-3">
                      <label>Promo Code</label>
                      <div className="promo-wrap position-relative">
                        <div className="promo-input">
                          <input
                            type="text"
                            placeholder="Enter Promo Code"
                            className="form-control"
                            name="promocode"
                            value={formData.promocode}
                            onChange={handleSelectChange}
                            disabled={
                              formData.promo_amount > 0 && formData.promocode
                            }
                          />
                        </div>
                        {formData.promo_amount > 0 && formData.promocode ? (
                          <Button
                            className="lms-btn-promo position-absolute"
                            onClick={() => handleRemovePromoClick()}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            className="lms-btn-promo position-absolute"
                            onClick={() => handlePromoClick()}
                          >
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="payment-feild cost-info mt-4">
                      <ul className="list-unstyled">
                        <li className="d-flex justify-content-between">
                          <h4>Package price</h4>
                          <span>
                            &euro;
                            {formData.package_price}
                          </span>
                        </li>
                        {formData?.vat_amount > 0 && (
                          <li className="d-flex justify-content-between">
                            <h4>VAT ({formData.vat_percent}%)</h4>
                            <span>
                              &euro;
                              {formData?.vat_amount}
                            </span>
                          </li>
                        )}
                        {planData?.discount_amount ? (
                          <li className="d-flex align-items-center justify-content-between">
                            <h4>Discount ({planData.discount_amount}%)</h4>
                            <span>
                              &euro;
                              {formData.discount}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        {formData.promo_amount ? (
                          <li className="d-flex align-items-center justify-content-between">
                            <h4>Promocode discount</h4>
                            <span>
                              &euro;
                              {formData.promo_amount}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        <li className="gt-pay d-flex align-items-center justify-content-between">
                          <h4>Total</h4>
                          <span>
                            &euro;
                            {formData.total_price}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="form-wrap-bt">
                      <div className="form-check checkbox-container mb-4">
                        <input
                          className="form-check-input custom-checkbox"
                          type="checkbox"
                          value=""
                          checked={formData.is_term_accept === 1}
                          id="confterms"
                          onChange={checkTerm}
                        />
                        <label
                          className="form-check-label position-relative"
                          htmlFor="confterms"
                        >
                          I have read & agree to the{" "}
                          <Link to="/terms-and-conditions" target="_blank">
                            Terms & Conditions
                          </Link>
                          ,{" "}
                          <Link to="/privacy-policy" target="_blank">
                            Privacy Policy
                          </Link>{" "}
                          and{" "}
                          <Link to={"/fca-disclaimer"} target="_blank">
                            FCA Disclaimer
                          </Link>
                          .
                        </label>
                      </div>

                      <div className="form-check checkbox-container mb-4">
                        <input
                          className="form-check-input custom-checkbox"
                          type="checkbox"
                          value=""
                          checked={formData.is_confirm_payment_accept === 1}
                          id="confCondition"
                          onChange={checkConfirm}
                        />
                        <label
                          className="form-check-label position-relative"
                          htmlFor="confCondition"
                        >
                          By confirming your subscription, you allow The
                          Merchant to charge your card for this payment and
                          future payments in accordance with their terms. You
                          can always cancel your subscription.
                        </label>
                      </div>
                      <p
                        className="text-danger"
                        style={{ marginBottom: "14px" }}
                      >
                        {errorAggrement}
                      </p>
                      <Button
                        className="btn pur-btn"
                        onClick={(e) => handleToBuy(e)}
                      >
                        Purchase
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default PaymentPage;
