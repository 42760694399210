import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Logo from "../assets/images/logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordApi } from "../api/authApi";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Loader from "../components/loader/loader";
import { Helmet } from "react-helmet";

export default function ConfirmPassword() {
  let { id, email } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmData, setConfirmData] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  });
  const [validation, setValidation] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  });

  useEffect(() => {

    setConfirmData({
      ...confirmData,
      email: email,
      token: id,
    });
  }, []);

  const handleChangeInput = (event) => {
    let { name, value } = event.target;

    setConfirmData({
      ...confirmData,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const resetPasswordMutation = useMutation(resetPasswordApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        navigate("/login");
      }
      return;
    },
    onError: (error) => {
      console.log("error", error);
      setLoading(false);
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const onEmailSubmitHandler = () => {
    setLoading(true);
    if (validateForm()) {
      resetPasswordMutation.mutate(confirmData);
    }
  };

  const validateForm = () => {
    const formKeys = Object.keys(confirmData);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], confirmData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
      setLoading(false);
    }
    return valid;
  };

  const validate = (name, value) => {
    if (name === "password") {
      if (!value) {
        return "Please enter password";
      }
    } else if (name === "password_confirmation") {
      if (!value) {
        return "Please confirm your password";
      } else if (value !== confirmData.password) {
        return "Confirm password does not matched";
      }
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>Eltaajir | Confirm Password</title>
        <link
          rel="canonical"
          href="https://eltaajir.com/confirm-password/:id"
        />
        <meta property="og:title" content="Eltaajir | Confirm Password" />
      </Helmet>
      {loading && <Loader />}
      <section className="login-page">
        <div className="logoin-box-shadow">
          <Row className="p-0 m-0 align-items-center">
            <Col md={6} lg={8} className="p-0 m-0">
              <div className="login-img">
                <Image
                  src={require("../assets/images/add-logoin-img.png")}
                  className="w-100"
                />
              </div>
            </Col>
            <Col md={6} lg={4} className="p-0 m-0">
              <div className="login-form">
                <div className="logo-login">
                  <Image src={Logo} onClick={() => navigate("/")} />
                </div>
                <p>Reset Password</p>
                <Form className="log-in-input">
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label className="form-new-lable">Password</Form.Label>
                    <Form.Control
                      className={`input-box ${
                        validation.password ? "error-add" : ""
                      }`}
                      placeholder="Enter New Password"
                      type="password"
                      name="password"
                      value={confirmData.password}
                      onChange={handleChangeInput}
                    />
                    <span className="error-new">{validation.password}</span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label className="form-new-lable">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      className={`input-box ${
                        validation.password_confirmation ? "error-add" : ""
                      }`}
                      placeholder="Enter Confirm Password"
                      type="password"
                      name="password_confirmation"
                      value={confirmData.password_confirmation}
                      onChange={handleChangeInput}
                    />
                    <span className="error-new">
                      {validation.password_confirmation}
                    </span>
                  </Form.Group>
                  <Button
                    className="login-btn"
                    variant="primary"
                    onClick={() => onEmailSubmitHandler()}
                  >
                    Submit
                  </Button>
                </Form>

                <div className="separator"></div>
                <Button
                  className="sign-up-btn"
                  variant="primary"
                  type="submit"
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
