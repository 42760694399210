import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";

import { blogList, blogPriorityApi } from "../../api/blogApi";
import TopBanner from "../../components/banner/TopBanner";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";
import ChevronRight from "../../assets/svg/ChevronRight";
import ChevronLeft from "../../assets/svg/ChevronLeft";
import DefaultBlogImg from "../../assets/images/default_video_image.png";
import ReactPaginate from "react-paginate";
import YouTube from "react-youtube";
import { PlayBtn } from "../../assets/svg/PlayBtn";

export default function MyNotesList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [singleBlog, setSingleBlog] = useState();
  const [currentBlogList, setCurrentBlogList] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [blogData, setBlogData] = useState({
    page: 1,
    order: "desc",
    record_per_page: 20,
  });
  const opts = {
    width: "853",
    height: "480",
    playerVars: {
      autoplay: 1,
    },
  };

  useEffect(() => {
    setLoading(true);
    blogListMutation.mutate(blogData);
  }, [blogData]);

  useEffect(() => {
    blogPriority.mutate({ ...blogData, is_priority: 1 });
  }, []);

  const handlePageClick = (event) => {
    setBlogData({
      ...blogData,
      page: event.selected + 1,
    });
  };

  const blogSort = (e) => {
    setBlogData({
      ...blogData,
      order: e.target.value,
    });
  };

  const checkChars = (str, type) => {
    var max;
    if (type === "allBlog") {
      max = 500;
    } else {
      max = 1000;
    }
    return str.length > max ? str.substring(0, max) + "..." : str;
  };

  // replace image function
  const replaceImage = (error) => {
    error.target.src = DefaultBlogImg;
  };

  const blogListMutation = useMutation(blogList, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setCurrentBlogList(data?.data?.data);
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      return;
    },
    onError: () => {
      setLoading(false);
    },
  });

  const blogPriority = useMutation(blogPriorityApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data) {
        setSingleBlog(data?.data?.data[0]);
      }
      return;
    },
    onError: () => {
      setLoading(false);
    },
  });

  const handleNavigate = (singleBlog) => {
    navigate(`/blog?bid=${singleBlog.id}`);
  };

  return (
    <>
      <Helmet>
        <title>Eltaajir - Forex Notes</title>
        <link rel="canonical" href="https://eltaajir.com/my-notes" />
        <meta property="og:title" content="The Merchant - Blogs" />
      </Helmet>
      {loading && <Loader />}
      <TopBanner title="My Blog" />
      <section className="mynotes-list-sec">
        <Container>
          <Row>
            <div className="blog-sort-wrap">
              <Form.Select
                aria-label="label"
                defaultValue="new-old"
                onChange={(e) => blogSort(e)}
              >
                <option value="desc">New to old</option>
                <option value="asc">Old to new</option>
              </Form.Select>
            </div>
            <div className="mynotes-list-wrap single-blog">
              {singleBlog && (
                <>
                  <section className="single-blog-page">
                    <div className="single-blog-wrap">
                      <div className="blog-desc-wrap">
                        <Row>
                          <Col sm={12} lg={5} xl={5}>
                            {singleBlog?.youtube_link && (
                              <div className="blog-youtube-video">
                                {showVideo ? (
                                  <YouTube
                                    videoId={singleBlog?.youtube_link}
                                    opts={opts}
                                    iframeClassName="yt-frame"
                                    className="yt-wrap"
                                  />
                                ) : (
                                  <div
                                    className="blog-youtube-video-inner"
                                    onClick={() => setShowVideo(true)}
                                  >
                                    <img
                                      src={`https://eltaajir.com/admin//storage/app/public/${singleBlog?.blog_image}`}
                                      alt="Custom Thumbnail"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <div className="play-btn">
                                      <PlayBtn />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {!singleBlog?.youtube_link && (
                              <div className="single-blog-img">
                                <img
                                  src={`https://eltaajir.com/admin//storage/app/public/${singleBlog?.blog_image}`}
                                  alt="Custom Thumbnail"
                                  className="w-100"
                                />
                              </div>
                            )}
                          </Col>
                          <Col sm={12} lg={7} xl={7}>
                            <div className="single-blog-title">
                              <h2 onClick={() => handleNavigate(singleBlog)}>
                                {singleBlog?.blog_title}
                              </h2>
                            </div>
                            <div
                              className="single-blog-desc"
                              dangerouslySetInnerHTML={{
                                __html: checkChars(
                                  singleBlog.description,
                                  "pinBlog"
                                ),
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
            <div className="mynotes-list-wrap">
              {currentBlogList?.data?.length > 0 &&
                currentBlogList?.data?.map((item, ind) => {
                  return (
                    <div
                      className="mynotes-list-card d-flex align-items-center"
                      key={ind}
                      onClick={() => handleNavigate(item)}
                    >
                      <Col className="mynotes-list-card-col card-left">
                        <div className="mynotes-list-card-img">
                          <div className="mynotes-list-card-imginner position-relative ratio ratio-16x9">
                            <Image
                              src={`https://eltaajir.com/admin//storage/app/public/${item.blog_image}`}
                              className="w-100"
                              onError={replaceImage}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col className="mynotes-list-card-col card-right">
                        <div className="mynotes-list-card-ctn">
                          <h4>{item.blog_title}</h4>
                          {item.description && (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: checkChars(
                                    item.description,
                                    "allBlog"
                                  ),
                                }}
                              ></div>
                            </>
                          )}
                        </div>
                      </Col>
                    </div>
                  );
                })}
            </div>
            {currentBlogList.last_page > 1 && (
              <div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<ChevronRight />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={currentBlogList.per_page}
                  pageCount={currentBlogList.last_page}
                  previousLabel={<ChevronLeft />}
                  forcePage={blogData.page - 1}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-center"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeLinkClassName="active"
                  pageClassName="page-item"
                  nextClassName="next page-item"
                  previousClassName="previous page-item"
                  breakClassName="break page-item"
                  breakLinkClassName="page-link"
                />
              </div>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
}
